<template>
    <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
        <div>
            <FieldGroup_input
                :field-group_class="'mb__12'"
                :id="'name'"
                :label="$t('Unit.Name')"
                :error="errors.name"
                v-model="unit.name"
            />

            <FieldGroup_kwrapper_dropdownlist
                :field-group_class="'field-group mb__12'"
                :id="'creator'"
                :label="$t('Unit.Creator')"
                :items="creators"
                :field_id="'id'"
                :field_name="'login'"
                :error="errors.creator"
                v-model="unit.creator"
                v-if="false"
            />

            <FieldGroup_input
                    :field-group_class="'mb__12'"
                    :id="'phone'"
                    :label="$t('Unit.Phone (SIM)')"
                    :error="errors.phone"
                    v-model="unit.phone"
            />

            <FieldGroup_input
                :field-group_class="'field-group mb__12'"
                :id="'creator'"
                :label="$t('Unit.Creator')"
                :error="errors.creator"
                v-model="unit.creator"
                v-if="false"
            />

            <FieldGroup_input
                :field-group_class="'mb__12'"
                :id="'imei'"
                :label="$t('Unit.IMEI')"
                :error="errors.hw_id"
                v-model.trim="unit.hw_id"
                :disabled="!isNew"
            />

            <div class="horizontal-line mb__12">
                <template v-if="!isAddHwType">
                    <FieldGroup_kwrapper_dropdownlist
                        :id="'hw_type'"
                        :label="$t('Unit.Hardware')"
                        :items="hw_types"
                        :field_id="'hw_type'"
                        :field_name="'hw_title'"
                        :error="errors.hw_type"
                        v-model.trim="unit.hw_type"
                        v-if="isNew"
                    />
                    <FieldGroup_input
                        :id="'hw_type'"
                        :label="$t('Unit.Hardware')"
                        v-model="unit_hw_type"
                        :disabled="!isNew"
                        v-if="!isNew"
                    />
                </template>

                <FieldGroup_input
                    :field-group_class="'field-group'"
                    :id="'hwType'"
                    :label="$t('Unit.Hardware')"
                    v-model="hwType.hw_type"
                    :error="errors.hw_type"
                    v-if="isAddHwType && isNew && isSuperAdmin"
                />

                <a :href="unit__hw_type_help_link" target="_blank">
                    <button class="button button_view_icon button_theme_base ml__8">
                        <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__info_link"/></span> </span>
                    </button>
                </a>

                <template v-if="isNew && isSuperAdmin">
                    <button class="button button_view_icon button_theme_base ml__8"
                            v-if="!isAddHwType"
                            @click="clearHwType">
                        <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__add_lg"/></span> </span>
                    </button>
                    <button class="button button_view_icon button_theme_base is_danger ml__8"
                            v-if="isAddHwType"
                            @click="clearHwType">
                        <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__clear_lg"/></span> </span>
                    </button>
                </template>
            </div>

            <div class="fast-user"
                 v-if="isNew && isSuperAdmin"
                 :class="{
                    'fast-user--active': isAddHwType,
                    'fast-user--active-errors': errors.hw_title > '',
                 }"
            >
                <FieldGroup_input
                    :field-group_class="'field-group'"
                    :id="'title'"
                    :label="'Title'"
                    :error="errors.hw_title"
                    v-model="hwType.hw_title"
                />

            </div>

            <template>
                <FieldGroup_kwrapper_dropdownlist
                    :id="'reefer_type'"
                    :label="$t('Unit.Reefer type')"
                    :items="reefer_types"
                    :field_id="'reefer_type'"
                    :field_name="'reefer_title'"
                    :error="errors.reefer_type"
                    v-model.trim="unit.reefer_type"
                    v-if="unit.hw_type === hwTypes.globe_tracker"
                />
            </template>
        </div>
    </section>
</template>
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {userStatus} from "@/lib/user-status";

    export default {
        name: 'UnitEdit_Options',
        props: [
            'value',
            'unitId',
            'errors',
        ],
        components: {},
        data() {
            return {
                isAddHwType: false,
                hwTypes:{
                    'globe_tracker':'globe_tracker'
                },
            }
        },
        computed: {
            ...mapGetters([
                "getUsers",
                "unitsByIds",
                "isSuperAdmin",
                "getUnitsIcons",
                "getUnitsHwTypes",
                "getUnitsTypes",
                "getFilterSearchLists",
                "getUsers",
                "getUnitsReeferTypes",
            ]),
            creators(){
              return this.getUsers.filter( u => u.status != userStatus.DELETED )
            },
            isNew(){
               return !(this.unitId && this.unitId>0)
            },
            _errors(){//hack on update
                return Object.keys(this.errors)
            },
            unit(){
                return this.value
            },
            hw_types(){
                return this.getUnitsHwTypes
            },
            reefer_types(){
                return this.getUnitsReeferTypes
            },
            types(){
                return this.getUnitsTypes
            },
            unit_hw_type(){
                let hw_type = this.hw_types.find(type => {
                  return type.hw_type == this.unit.hw_type
                })
                return hw_type? hw_type.hw_title : ''
            },
            unit__hw_type_help_link(){
                return '/help/types/'+ ((this.unit.hw_type>'') ? this.unit.hw_type : '')
            },
            hwType(){
              return this.unit.hwType || {}
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
            ]),
            clearHwType(){
                this.isAddHwType = !this.isAddHwType
                this.unit.hwType = this.isAddHwType ? {} : null
                this.unit.hw_type = ''
                delete this.errors.hw_type
                delete this.errors.hw_title
            },
            created() {
                // console.log('UnitEdit_Options created', this.unitId, {...this.unit})
            },
            updated() {
                // console.log('UnitEdit_Options updated', this.unitId, {...this.unit})
            },
            mounted() {
                // console.log('UnitEdit_Options mounted', this.unitId, {...this.unit})
            }
        }
    }
</script>

<style scoped>
.fast-user{
    overflow: hidden;
    height: 0;
    transition: .3s;
}
.fast-user--active{
    height: 68px;
    transition: .3s;
}
.fast-user--active.fast-user--active-errors{
    height: 96px;
    transition: .3s;
}
.field-group__input{
    height: 0;
    transition: .3s;
}
.fast-user--active .field-group__input {
    height: 3.5rem;
    transition: .3s;
}
</style>